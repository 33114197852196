/* eslint-disable */
import get from 'lodash/get'
/**
 * 提供在所有页面都可以使用的通用的方法
 */
export default {
  trimAll,
  addZero,
  onlyNum,
  closeWin,
  targetSelf,
  setXLocalStorage,
  loadJs,
  formatStamp,
  isEmptyObject,
  getBrowserVersion,
  getUrlQuery,
  getOffsetTop,
  getOffsetLeft,
  scrollTo,
  isSameArray,
  safeHtml,
  checkParams,
  randomBytes,
  wait,
  getUrlParams,
  /**
   * 访问sessionStorage接口，支持直接读写object。这里的取值表示是否在读取过一次后就删除。用法如下
   * util.sessionStorage.XX_DATA_FOMR_OO_PAGE = 'abc'
   * util.sessionStorage.XX_DATA_FOMR_OO_PAGE = {b:1, c:[234,{d:'ab"cd\'efg'}]}
   * console.log(util.sessionStorage.XX_DATA_FOMR_OO_PAGE)
   */
  sessionStorage: initStorage(sessionStorage, {
    // 当前选中的商品信息
    SELECTED_GOODS_INFO: false,
    // 统计跟踪代码
    TRACK_FROM: false,
    // 邀请码
    PROMO_CODE: false,
  }),
  /**
   * 访问localStorage接口，支持直接读写object。这里的取值表示是否在读取过一次后就删除。用法同sessionStorage
   */
  localStorage: initStorage(localStorage, {
    // 登录后的token。在py.xxxx.com域下产生，会同步写入xxxx.com域
    LUXY_TOKEN: false,
    // 登录后的个人信息字符串。目前包含avatar和name两个字段。在py.xxxx.com域下产生，会同步写入xxxx.com域
    LUXY_MY_PROFILE: false,
    // 登录页面保存的vouch状态，用于进入登录页面时判断跳转到哪。0未知，1正在vouch，2in，3out
    LAST_VOUCH_STATUS: false,
    // 联盟推广码。TODO 在官网首页设置的。目前已经跨域读不到了
    LUXY_HOMEPAGE_AFCODE: false,
    // 需要上报vouchIn事件的新用户标记
    NEED_VOUCH_IN_REPORT: true,
    // 已进行过欧盟测试和GDPR弹框的用户标记
    GDPR_TEST_FINISH: false,
  }),
  finishPrerender,
  isMobileBrowse,
  isIphoneX,
  fillSpecifiedLengthArray,
  getCurrentZoneTime,
  isIphonePlus,
}


/**
 * 检测当前环境是否为iPhone Plus环境
 * @return {boolean} true: iphone plus
 */
export function isIphonePlus() {
  if (typeof window !== 'undefined' && window) {
    return /iphone/gi.test(window.navigator.userAgent) && window.screen.height === 736
  }
  return false
}

/**
 * 初始化storage代理
 * @param {Storage} stub
 * @param {object} propMap
 */
function initStorage(stub, propMap) {
  // PhantomJs预渲染时不支持Proxy
  if (typeof Proxy === 'undefined') {
    return {}
  }
  return new Proxy({}, {
    get(target, key) {
      return getItem(key, propMap[key])
    },
    set(target, key, value) {
      setItem(key, value)
      // 必须返回true或抛异常，否则会报错'set' on proxy: trap returned falsish for property 'xxx'
      return true
    },
  })

  function getItem(key, isOneshot) {
    const realKey = getRealKey(key)
    let value = stub.getItem(realKey)
    if (value !== undefined) {
      try {
        value = JSON.parse(value)
      } catch (e) {
        if (e.name === 'SyntaxError') {
          console.error(`can't parse [${realKey}]: ${value}`)
          removeItem(key)
        } else {
          console.error(e)
        }
        value = undefined
      }
      if (isOneshot) {
        removeItem(key)
      }
    }
    return value
  }

  function setItem(key, value) {
    if (value === undefined) {
      removeItem(key)
      return
    }
    const realKey = getRealKey(key)
    try {
      stub.setItem(realKey, JSON.stringify(value))
    } catch (e) {
      if (e.name === 'QuotaExceededError' && stub.length) {
        // 空间不足
        stub.clear()
        stub.setItem(realKey, JSON.stringify(value))
      } else {
        console.error(e)
      }
    }
  }

  function removeItem(key) {
    const realKey = getRealKey(key)
    try {
      stub.removeItem(realKey)
    } catch (e) {
      console.error(e)
    }
  }

  function getRealKey(key) {
    return `${key}${window.gDevEnv ? '_dev' : ''}`
  }
}

export const localStorageObj = initStorage(localStorage, {
  // 登录后的token。在py.xxxx.com域下产生，会同步写入xxxx.com域
  LUXY_TOKEN: false,
  // 登录后的个人信息字符串。目前包含avatar和name两个字段。在py.xxxx.com域下产生，会同步写入xxxx.com域
  LUXY_MY_PROFILE: false,
  // 登录页面保存的vouch状态，用于进入登录页面时判断跳转到哪。0未知，1正在vouch，2in，3out
  LAST_VOUCH_STATUS: false,
  // 联盟推广码。TODO 在官网首页设置的。目前已经跨域读不到了
  LUXY_HOMEPAGE_AFCODE: false,
  // 需要上报vouchIn事件的新用户标记
  NEED_VOUCH_IN_REPORT: true,
  // 已进行过欧盟测试和GDPR弹框的用户标记
  GDPR_TEST_FINISH: false,
})

/**
 * 删除所有空格
 * @param {string} str
 * @return {string}
 */
export function trimAll(str) {
  return str.replace(/\s/g, '')
}

/**
 * 小于10，补0函数，常用于时间结构
 * @param {number} num
 * @return {string}
 */
export function addZero(num) {
  return num < 10 ? `0${num}` : num
}

/**
 * 去除非数字字符
 * @param {string} str
 * @return {string}
 */
export function onlyNum(str) {
  return str ? str.replace(/\D/gi, '') : str
}

/**
 * 关闭当前标签页
 */
export function closeWin() {
  window.opener = null
  window.open('', '_self')
  window.close()
}

/**
 * 在当前页面跳转，external是否为外部域名
 * @param {string} url
 * @param {boolean?} external
 */
export function targetSelf(url, external = false) {
  if (!external) {
    url = `${window.location.protocol}//${window.location.host}${url}`
  }
  console.log('navigate top to', url)
  window.top.location = url
}

/**
 * 向官网所在的域a-puhaha.com写入localStorage
 * @param {string} writerUrl 域名，如http://a-puhaha.com/static/xxx.html
 * @param {object} data 要保存的数据，key是保存时的键，value必须是字符串
 * @return {Promise}
 */
export function setXLocalStorage(writerUrl, data = {}) {
  const search = Object.entries(data)
    .map(([key, value]) => {
      key = key && encodeURIComponent(key)
      value = value && encodeURIComponent(value)
      return `${key}=${value}`
    })
    .join('&')
  if (search) {
    const url = `${writerUrl}?${search}`
    return createIframe(url)
  } else {
    return Promise.reject(new Error('no data saved'))
  }
}

/**
 * 创建一个隐形的iframe
 * @param {string} url
 * @return {Promise} iframe的window对象，可用来postMessage
 */
function createIframe(url) {
  return new Promise(resolve => {
    const frame = document.createElement('iframe')
    frame.style.cssText = 'width:1px;height:1px;border:0;position:absolute;left:-9999px;top:-9999px;'
    frame.setAttribute('src', url)
    // 子域名不同时也不能访问frame.contentWindow，会直接报错
    frame.onload = resolve
    document.body.appendChild(frame)
  })
}

/**
 * 异步加载js，并返回promise
 * @param {string} url 所要加载的xx.js。不用require是因为一些压缩过的三方库，引入webpack打包流程会拖慢打包速度
 * @param {string} importedObjName 是否已经加载xx.js的方法名或对象或参数
 * @param {string?} id script标签的id，防止重复加载。不填则允许重复加载
 * @param {number=30000} timeout 加载超时时间
 * @returns {Promise}
 */
export function loadJs(url, importedObjName, id, timeout = 30000) {
  if (window[importedObjName]) {
    return Promise.resolve(window[importedObjName], false)
  }
  const jsId = id || `${randomString(8)}-js`
  const INTERVAL = 100
  const notLoadBefore = !document.getElementById(jsId)
  return new Promise((resolve, reject) => {
    let newElem = null
    // 加载经过的时间
    let total = 0
    let timer = null
    if (notLoadBefore) {
      newElem = document.createElement('script')
      newElem.id = jsId
      newElem.src = url
      newElem.onerror = () => callReject(new Error(`Load ${url} failed`))
      document.getElementsByTagName('head')[0].appendChild(newElem)
    }
    if (window[importedObjName]) {
      callResolve()
      return
    }
    timer = setInterval(() => {
      total += INTERVAL
      if (total > timeout) {
        // 注意超时后我们把原script标签移除掉了。但是还是有可能加载成功并执行原script
        callReject(new Error(`Load ${url} timeout`))
      } else if (window[importedObjName]) {
        callResolve()
      }
    }, INTERVAL)

    function callResolve() {
      console.log(importedObjName, 'loaded')
      if (timer) {
        clearInterval(timer)
      }
      // 第二个参数表示是否是首次加载完毕，可以依据此做一些初始化工作
      resolve(window[importedObjName], !!newElem)
    }

    function callReject(err) {
      if (timer) {
        clearInterval(timer)
      }
      // 移除掉当前元素，这样才能重试加载
      if (newElem) {
        newElem.remove()
      }
      reject(err)
    }
  })
}

/**
 * 生成指定长度的随机字符串序列
 * @param {number?} len
 * @returns {string}
 */
function randomString(len = 32) {
  const chars = 'GQM5s7KdZhr8zFV3X4CHfU6kIq2cgTBDnoJamSyNOeYW9Rt01pLblvwiuPExjA'
  const maxPos = chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

/**
 * 时间戳转世界时间
 * @param {number} time
 * @param {string} format 时间格式字符串 "yyyy-MM-dd EEE hh:mm:ss"
 * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * @return {string}
 * @example
 * "yyyy-MM-dd hh:mm:ss.S" ==> 2006-07-02 08:09:04.423
 * "yyyy-M-d h:m:s.S"      ==> 2006-7-2 8:9:4.18
 */
export function formatStamp(time, format) {
  format = format === null ? 'yyyy/MM/dd hh:mm' : format
  const date = new Date()
  date.setTime(time * 1000)
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  }
  if (/(y+)/.test(format)) format = format.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length))
  for (const k in o) {
    if (new RegExp(`(${k})`).test(format))
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length))
  }
  return format
}

/**
 * 检测一个对象是否是空对象
 * @param {object} obj
 * @return {boolean}
 */
export function isEmptyObject(obj) {
  for (const key in obj) {
    // 如果obj是Object.create(null)创建出来的，就没有prototype，也没有hasOwnProperty
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }
  return true
}

/**
 * 检查移动设备类型
 * @return {boolean} 是否为移动端
 */
export function isMobileBrowse() {
  return /AppleWebKit.*Mobile.*/i.test(window.navigator.userAgent)
}

/*
 * 设备检测
 * @return {{isIos:Function, isAndroid:Function}}
 */
export function getBrowserVersion() {
  const ua = navigator.userAgent || navigator.vendor || window.opera
  const uaInfo = {
    macOS: /macintosh|mac os x/i.test(ua),
    ios: /\(i[^;]+;( U;)? CPU.+Mac OS X/i.test(ua),
    android: /Android/i.test(ua) || /Linux/i.test(ua),
  }
  return {
    isMacOS() {
      return uaInfo.macOS
    },
    isIos() {
      return uaInfo.ios
    },
    isAndroid() {
      return uaInfo.android
    },
  }
}

/**
 * 获取url中的指定参数
 * @param   {string} name url中的参数名字
 * @param   {string?} url 不填则使用当前地址
 * @returns {null | string} 若获取失败则返回null
 */
export function getUrlQuery(name, url) {
  const matcher = (url || window.location.search).match(`${name}=([^&#]+)`)
  if (!matcher || matcher.length < 2) {
    console.log(`No "${name}" in url`)
    return null
  }
  return matcher[1]
}

/**
 * 获取当前history模式 url中的所有参数
 * @param   {string} url 要获取的url地址（默认当前的url）
 * @returns {object} 若获取返回的所有参数对象键值对
 */
export function getUrlParams(url = window.location.href) {
  try {
    url = url.match(/\?([^#]+)/)[1]
    var obj = {},
      arr = url.split('&')
    for (var i = 0; i < arr.length; i++) {
      var subArr = arr[i].split('=')
      obj[subArr[0]] = subArr[1]
    }
    return obj
  } catch (err) {
    return {}
  }
}

/**
 * 获取指定元素距离网页顶部的距离
 * @param elem
 * @returns {number}
 */
export function getOffsetTop(elem) {
  let top = 0
  while (elem) {
    top += elem.offsetTop
    elem = elem.offsetParent
  }
  return top
}

/**
 * 获取指定元素距离网页左边的距离
 * @param elem
 * @returns {number}
 */
export function getOffsetLeft(elem) {
  let left = 0
  while (elem) {
    left += elem.offsetLeft
    elem = elem.offsetParent
  }
  return left
}

/**
 * 滚动到指定的元素
 * @param {string} selector 元素选择器
 */
export function scrollTo(selector) {
  const elem = document.querySelector(selector)
  console.log('Hobby Test: scrollTo -> elem', elem)
  if (elem) {
    document.body.scrollTop = document.documentElement.scrollTop =
      getOffsetTop(elem) - (document.documentElement.clientHeight - elem.clientHeight) / 2
  }
}

/**
 * 比较两个数组是否相同
 * @param {Array} array1
 * @param {Array} array2
 * @param {Function} [comparator] 数组元素的比较器，传入参数为两个元素值，返回boolean
 */
export function isSameArray(array1, array2, comparator) {
  if (array1.length !== array2.length) {
    return false
  } else if (!array1.length) {
    return true
  }
  if (comparator) {
    return array1.every(item1 => array2.some(item2 => comparator(item1, item2)))
  } else {
    return array1.every(item => array2.includes(item))
  }
}

/**
 * 对用户输入的字符串进行转义，防止xss攻击。需要用v-html方式显示的用户内容一定要调用此方法
 * @param {string} str
 */
export function safeHtml(str) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/(\r\n|\n)/g, '<br>')
}

/**
 * 检查obj对象的成员类型
 * @param {object} obj 待检查的对象
 * @param {object} paramMaps obj中要检查的成员名和成员类型字符串的映射表。
 *                          类型值为'undefined','number','array'等，末尾加问号表示该参数可选
 * @param {string?} objDisplayName 打印错误日志时用的obj名字
 * @returns {boolean} 是否全部合格
 *
 * @example
 * checkParams(obj, {title:'string', 'school.name':'string', books:'array', 'remark': 'string?'}
 */
export function checkParams(obj, paramMaps, objDisplayName = 'obj') {
  if (obj === undefined) {
    console.error(`${objDisplayName} is undefined`)
    return false
  }
  const allType = ['undefined', 'object', 'boolean', 'number', 'string', 'function', 'symbol', 'array']
  return Object.entries(paramMaps).every(([field, type]) => {
    // 检查type参数
    if (typeof type !== 'string') {
      console.error(`type is ${type}, expect one of ${JSON.stringify(allType)}`)
      return false
    }
    const optional = /\w+\?/.test(type)
    if (optional) {
      type = type.slice(0, -1)
    }
    if (!allType.includes(type.toLowerCase())) {
      console.error(`type is ${type}, expect one of ${JSON.stringify(allType)}`)
      return false
    }
    type = type.toLowerCase()

    // 检查obj中对应属性的取值
    const value = get(obj, field)
    if (optional && value === undefined) {
      return true
    }
    if (type === 'array') {
      if (!Array.isArray(value)) {
        console.error(`${objDisplayName}.${field} is ${value}, expect a ${type}`)
        return false
      }
    } else if (typeof value !== type) {
      // eslint-disable-line valid-typeof
      console.error(`${objDisplayName}.${field} is ${value}, expect a ${type}`)
      return false
    }
    return true
  })
}

/**
 * 预渲染时通知编译进程结束预渲染用的方法
 * @return {boolean} 是否在预渲染环境中
 */
export function finishPrerender() {
  if (!window.gPrerenderEnv) {
    return false
  }
  const timer = setInterval(() => {
    if (typeof window.gFinishPrerender === 'function') {
      window.gFinishPrerender()
      clearInterval(timer)
    }
  }, 10)
  return true
}

// 验证手机号码
export function isPhoneNum(str) {
  // if (window.gDevEnv) {
  //     // 测试环境00开头跳过手机验证
  //     console.log('测试环境验证手机号')
  //     return /^(\+?86)?(00|1\d)\d{9}$/.test(str);
  // } else {
  //     console.log('正式环境验证手机号')
  //     return /^(\+?86)?1\d{10}$/.test(str);
  // }
  // 支持外国手机号
  return /^\+?\d{7,}$/.test(str)
}

// 生成指定位数的随机数
function randomBytes(length) {
  const charset = 'abcdef0123456789'
  let i
  let result = ''
  const bufferLength = length * 2
  if (window.crypto && window.crypto.getRandomValues) {
    const values = new Uint32Array(bufferLength)
    window.crypto.getRandomValues(values)
    for (i = 0; i < bufferLength; i++) {
      result += charset[values[i] % charset.length]
    }
    return result
  } else throw new Error("can't generate secure random numbers")
}

/**
 * 定时器promise
 * @param {number} duration 毫秒
 * @return {Promise}
 */
export function wait(duration) {
  return new Promise(resolve => setTimeout(resolve, duration))
}

/**
 * 判断是否是X系列
 * @return {Bollean}
 */
export function isIphoneX() {
  const isIPhoneX =
    /iphone/gi.test(window.navigator.userAgent) &&
    window.devicePixelRatio &&
    window.devicePixelRatio === 3 &&
    window.screen.width === 375 &&
    window.screen.height === 812
  // iPhone XS Max
  const isIPhoneXSMax =
    /iphone/gi.test(window.navigator.userAgent) &&
    window.devicePixelRatio &&
    window.devicePixelRatio === 3 &&
    window.screen.width === 414 &&
    window.screen.height === 896
  // iPhone XR
  const isIPhoneXR =
    /iphone/gi.test(window.navigator.userAgent) &&
    window.devicePixelRatio &&
    window.devicePixelRatio === 2 &&
    window.screen.width === 414 &&
    window.screen.height === 896
  // iPhone 12
  const isIPhone12 =
    /iphone/gi.test(window.navigator.userAgent) &&
    window.devicePixelRatio &&
    window.devicePixelRatio === 3 &&
    window.screen.width === 390 &&
    window.screen.height === 844
  // iPhone 12 Max
  const isIPhone12Max =
    /iphone/gi.test(window.navigator.userAgent) &&
    window.devicePixelRatio &&
    window.devicePixelRatio === 3 &&
    window.screen.width === 428 &&
    window.screen.height === 926
  return isIPhoneX || isIPhoneXSMax || isIPhoneXR || isIPhone12 || isIPhone12Max
}

/**
 * 检测元素是否出现在视窗内
 * @param {object} el 节点元素
 * @param {Number} 缓冲出现距离默认100（单位像素）
 * @return {bollean}
 */
export function isInViewPort(el, extra = -100) {
  const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  const top = el.getBoundingClientRect() && el.getBoundingClientRect().top
  console.log(top, viewPortHeight, extra)
  return top <= viewPortHeight + extra
}

/**
 * 得到一个两数之间的随机整数，包括两个数在内
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 * @return {Number}
 */
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min // 含最大值，含最小值
}

/**
 * 填充任意长度的数组
 * @param {Array} list 预设数组 已有的数组
 * @param {Number} arraylength 填充長度
 * @return {Array} 填充后的数组
 */
export function fillSpecifiedLengthArray(list = [], arraylength) {
  let result = []
  for (let index = 0; index < arraylength; index++) {
    result.push(list[index] || '')
  }
  return result
}

/**
 * 获取中英文字符占位
 * @param {String} str 字符串
 * @return {Number} 占位长度
 */
export function getStrLength(str) {
  let m = 0
  let a = str.split('')
  for (let i = 0; i < a.length; i++) {
    if (a[i].charCodeAt(0) < 299) {
      m++
    } else {
      m += 2
    }
  }
  return m
}

/**
 * 获取当前时区指定时区的时间戳
 * @param {Number} zone 第几时区
 * @return {Number} 处理后的时间戳
 */
export function getCurrentZoneTime(zone) {
  var timezone = zone //目标时区
  var offset_GMT = new Date().getTimezoneOffset() // 本地时间和格林威治的时间差，单位为分钟
  var nowDate = new Date().getTime() // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
  var targetDate = new Date(nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000) //当前东八区的时间
  var current = targetDate.getTime() //当前时区时间戳
  return current
}

/**
 * 返回区间范围内的随机整数 [MIN,MAX]
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 * @return {Number} 符合闭区间的整数值
 */
export function getRandomNum(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * 随机数组中随机取几个元素
 * @param {Array} arr 待操作数组
 * @param {Number} count 取出数量
 * @return {Array} 取出的数组元素
 */
export function getRandomArrayElements(arr, count) {
  let shuffled = arr.slice(0),
    i = arr.length,
    min = i - count,
    temp,
    index
  while (i-- > min) {
    index = Math.floor((i + 1) * Math.random())
    temp = shuffled[index]
    shuffled[index] = shuffled[i]
    shuffled[i] = temp
  }
  return shuffled.slice(min)
}
